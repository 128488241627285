import { mapActions } from 'vuex'
export default {
  name: 'join-event',
  components: {
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem')
  },
  data: () => ({
    isLoading: false
  }),
  mounted() {
    this.attendEvent()
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  computed: {
    eventName() {
      const text = this.$route.query.eventType.split('-')
      const textArr = []
      let textFix = ''
      for (let i = 0; i < text.length; i++) {
        const lowerCase = text[i]
        const camelCase = lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1)
        textArr.push(camelCase)
      }
      for (let k = 0; k < textArr.length; k++) {
        k === textArr.length - 1 ? (textFix += `${textArr[k]}`) : (textFix += `${textArr[k]} `)
      }
      return textFix
    }
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('program', ['attendOpenDay']),
    attendEvent() {
      this.isLoading = true
      this.showLoading()
      this.attendOpenDay({
        eventId: this.$route.query.eventId,
        payloads: {
          attendanceToken: this.$route.query.token
        }
      })
        .then((response) => {
          window.location.replace(response.data.data.eventLink)
        })
        .catch(() => {
          this.hideLoading()
          this.isLoading = false
        })
    }
  }
}
